import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentInterface } from "../interfaces/CommonInterface";
import { ReactSelectOption } from "../interfaces/SelectDropDownInterface";
import { VendorResponseArray } from "../types/VendorTypes";
import { CategoryResponseArray } from "../types/CategoryTypes";

const initialState: ComponentInterface = {
  vendorDropDown: [],
  vendorList: [],
  categoryDropDown: [],
  categoryList: []
};

const componentSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setVendorDropDown: (state, action: PayloadAction<ReactSelectOption[]>) => {
      state.vendorDropDown = action.payload;
    },
    setVendorList: (state, action: PayloadAction<VendorResponseArray>) => {
      state.vendorList = action.payload;
    },
    setCategoryDropDown: (state, action: PayloadAction<ReactSelectOption[]>) => {
      state.categoryDropDown = action.payload;
    },
    setCategoryList: (state, action: PayloadAction<CategoryResponseArray>) => {
      state.categoryList = action.payload;
    },
    clearAllModule: (state) => {
      state.vendorDropDown = [];
      state.vendorList = [];
      state.vendorDropDown = [];
      state.categoryList = [];
    },
  },
});

export const {
  setVendorDropDown,
  setVendorList,
  setCategoryDropDown,
  setCategoryList,
  clearAllModule,
} = componentSlice.actions;

export default componentSlice.reducer;
