import Swal from "sweetalert2";
import {
  AlertOptions,
  ConfirmationOptions,
} from "../interfaces/CommonInterface";
import { AlertType } from "../types/CommonTypes";

class SwalHelper {
  private static defaultMessages = {
    error: {
      title: "Oops...",
      text: "Something went wrong. Please try again later.",
    },
    success: {
      title: "Success!",
      text: "Operation completed successfully.",
    },
    warning: {
      title: "Warning",
      text: "Please check your input.",
    },
    info: {
      title: "Information",
      text: "Please note this information.",
    },
    delete: {
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      confirmButtonText: "Yes, delete it!",
      loadingText: "Deleting...",
    },
    update: {
      title: "Are you sure?",
      text: "Do you want to update this record?",
      confirmButtonText: "Yes, update it!",
      loadingText: "Updating...",
    },
    create: {
      title: "Create New",
      text: "Do you want to create this record?",
      confirmButtonText: "Yes, create it!",
    },
  };

  private static colors = {
    primary: "#3085d6",
    danger: "#d33",
    success: "#28a745",
    warning: "#ffc107",
    info: "#17a2b8",
    secondary: "#6c757d",
  };

  // Basic alert
  static show({
    icon,
    title = this.defaultMessages[icon].title,
    text = this.defaultMessages[icon].text,
  }: AlertOptions) {
    return Swal.fire({
      icon,
      title,
      text,
    });
  }

  // Basic confirmation dialog
  static async confirm({
    title = this.defaultMessages.delete.title,
    text = this.defaultMessages.delete.text,
    icon = "warning",
    confirmButtonText = this.defaultMessages.delete.confirmButtonText,
    cancelButtonText = "Cancel",
    confirmButtonColor = this.colors.primary,
    cancelButtonColor = this.colors.danger,
  }: ConfirmationOptions = {}) {
    const result = await Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonColor,
      cancelButtonColor,
      confirmButtonText,
      cancelButtonText,
    });

    return result.isConfirmed;
  }

  // Specific delete confirmation with API call handling
  static async confirmDelete(
    entity: string,
    apiCallback: () => Promise<any>
  ): Promise<boolean> {
    const swalInstance = Swal.fire({
      title: this.defaultMessages.delete.title,
      text: this.defaultMessages.delete.text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: this.colors.primary,
      cancelButtonColor: this.colors.danger,
      confirmButtonText: this.defaultMessages.delete.confirmButtonText,
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: async () => {
        try {
          // Disable cancel button during API call
          Swal.getCancelButton()?.setAttribute("disabled", "");

          await Swal.showLoading();
          await apiCallback();
          return true;
        } catch (error) {
          // Re-enable cancel button on error
          Swal.getCancelButton()?.removeAttribute("disabled");

          Swal.showValidationMessage(
            `Delete failed: ${
              error instanceof Error ? error.message : "Unknown error"
            }`
          );
          return false;
        }
      },
    });

    const result = await swalInstance;

    if (result.isConfirmed) {
      await this.success(`${entity} has been deleted successfully!`);
      return true;
    }

    return false;
  }

  // Specific update confirmation with API call handling
  static async confirmUpdate(
    entity: string,
    apiCallback: () => Promise<any>
  ): Promise<boolean> {
    const swalInstance = Swal.fire({
      title: this.defaultMessages.update.title,
      text: this.defaultMessages.update.text,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: this.colors.success,
      cancelButtonColor: this.colors.secondary,
      confirmButtonText: this.defaultMessages.update.confirmButtonText,
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      preConfirm: async () => {
        try {
          // Disable cancel button during API call
          Swal.getCancelButton()?.setAttribute("disabled", "");

          await Swal.showLoading();
          await apiCallback();
          return true;
        } catch (error) {
          // Re-enable cancel button on error
          Swal.getCancelButton()?.removeAttribute("disabled");

          Swal.showValidationMessage(
            `Update failed: ${
              error instanceof Error ? error.message : "Unknown error"
            }`
          );
          return false;
        }
      },
    });

    const result = await swalInstance;

    if (result.isConfirmed) {
      await this.success(`${entity} has been updated successfully!`);
      return true;
    }

    return false;
  }

  // Rest of the methods remain the same...
  static error(text?: string) {
    return this.show({
      icon: "error",
      text: text || this.defaultMessages.error.text,
      title: this.defaultMessages.error.title,
    });
  }

  static success(text?: string) {
    return this.show({
      icon: "success",
      text: text || this.defaultMessages.success.text,
      title: this.defaultMessages.success.title,
    });
  }

  static warning(text?: string) {
    return this.show({
      icon: "warning",
      text: text || this.defaultMessages.warning.text,
      title: this.defaultMessages.warning.title,
    });
  }

  static info(text?: string) {
    return this.show({
      icon: "info",
      text: text || this.defaultMessages.info.text,
      title: this.defaultMessages.info.title,
    });
  }

  static fetchError(entity: string) {
    return this.error(`Failed to fetch ${entity}. Please try again later.`);
  }

  static saveError(entity: string) {
    return this.error(`Failed to save ${entity}. Please try again later.`);
  }

  static deleteError(entity: string) {
    return this.error(`Failed to delete ${entity}. Please try again later.`);
  }

  static updateError(entity: string) {
    return this.error(`Failed to update ${entity}. Please try again later.`);
  }

  static saveSuccess(entity: string) {
    return this.success(`${entity} has been saved successfully!`);
  }

  static updateSuccess(entity: string) {
    return this.success(`${entity} has been updated successfully!`);
  }

  static deleteSuccess(entity: string) {
    return this.success(`${entity} has been deleted successfully!`);
  }

  static toast(title: string, icon: AlertType = "success") {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    return Toast.fire({
      icon,
      title,
    });
  }

  static loading(title: string = "Loading...") {
    return Swal.fire({
      title,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  static close() {
    Swal.close();
  }
}

export default SwalHelper;
