import api, { setNewAccessToken } from "./CoreRequest";
import { LogOutApiCall } from "./LoginRequest";
import { ApiResponse } from "../interfaces/ApiInterface";

export const getList = async (dispatch?: any, data?: any) => {
    try {
        const response = await api.get<ApiResponse>("/api/notification/list", {
            params: data,
        });

        if (response.result?.success && response.result?.access_token) {
            setNewAccessToken(response.result.access_token);
        }
        return response;
    } catch (err: any) {
        if (err?.response?.status === 401) {
            await LogOutApiCall(dispatch);
        }
        throw err;
    }
};

export const markAsRead = async (dispatch: any, id: string) => {
    try {
        const response = await api.patch<ApiResponse>(`/api/notification/mark-read/${id}`);
        if (response.result?.success && response.result?.access_token) {
            setNewAccessToken(response.result.access_token);
        }
        return response;
    } catch (err: any) {
        if (err?.response?.status === 401) {
            await LogOutApiCall(dispatch);
        }
        throw err;
    }
};

export const markAsAllRead = async (dispatch: any) => {
    try {
        const response = await api.patch<ApiResponse>(`/api/notification/mark-all-read`);
        if (response.result?.success && response.result?.access_token) {
            setNewAccessToken(response.result.access_token);
        }
        return response;
    } catch (err: any) {
        if (err?.response?.status === 401) {
            await LogOutApiCall(dispatch);
        }
        throw err;
    }
};
