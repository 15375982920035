import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, CombinedState } from "../interfaces/LoginInterface";
import { UserResponse } from "../interfaces/UserInterface";
import { DataListReloadInterface } from "../interfaces/CommonInterface";
import { NavigationResponseArray } from "../types/NavigationTypes";

const initialState: CombinedState = {
  user: null,
  accessToken: null,
  shouldReloadUserList: false,
  shouldReloadDepartmentList: false,
  shouldReloadCategoryList: false,
  shouldReloadExpenseList: false,
  shouldReloadRequestList: false,
  shouldReloadRoleList: false,
  shouldReloadPermissionList: false,
  isLoggingOut: false,
  shouldReloadVendorList: false,
  navigation: null,
  shouldReloadWHoldingTaxList: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
    },
    clearAuth: (state) => {
      state.user = null;
    },
    clearToken: (state) => {
      state.accessToken = null;
    },
    clearAll: (state) => {
      state.isLoggingOut = false;
      state.user = null;
      state.accessToken = null;
      state.shouldReloadUserList = false;
      state.shouldReloadDepartmentList = false;
      state.shouldReloadCategoryList = false;
      state.shouldReloadExpenseList = false;
      state.shouldReloadRequestList = false;
      state.shouldReloadRoleList = false;
      state.shouldReloadPermissionList = false;
      state.navigation = null;
      state.shouldReloadVendorList = false;
      state.shouldReloadWHoldingTaxList = false;
    },
    resetBooleanState: (state) => {
      state.isLoggingOut = false;
      state.shouldReloadUserList = false;
      state.shouldReloadDepartmentList = false;
      state.shouldReloadCategoryList = false;
      state.shouldReloadExpenseList = false;
      state.shouldReloadRequestList = false;
      state.shouldReloadRoleList = false;
      state.shouldReloadPermissionList = false;
      state.shouldReloadVendorList = false;
      state.shouldReloadWHoldingTaxList = false;
    },
    setNavigation: (state, action: PayloadAction<NavigationResponseArray>) => {
      state.navigation = action.payload;
    },
    clearNavigation: (state) => {
      state.navigation = null;
    },
    setReloadList: (
      state,
      action: PayloadAction<{
        key: keyof DataListReloadInterface;
        value: boolean;
      }>
    ) => {
      const { key, value } = action.payload;
      (state as CombinedState)[key] = value;
    },
    resetAllReloadFlags: (state) => {
      Object.keys(state).forEach((key) => {
        if (key.startsWith("shouldReload")) {
          (state as CombinedState)[key as keyof DataListReloadInterface] =
            false;
        }
      });
    },
  },
});

export const {
  setAccessToken,
  setUser,
  clearAuth,
  clearToken,
  setReloadList,
  resetAllReloadFlags,
  clearAll,
  setNavigation,
  clearNavigation,
  resetBooleanState,
} = authSlice.actions;

// Updated Selectors
export const selectUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectReloadList = (
  state: RootState,
  flag: keyof DataListReloadInterface
) => (state.auth as CombinedState)[flag];

export default authSlice.reducer;
