import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

// Get the full domain including protocol
export const getFullDomain = () => window.location.origin;

// Get just the hostname (domain with subdomain if present)
export const getHostname = () => window.location.hostname;

// Get the domain name without subdomain
export const getDomainWithoutSubdomain = () => {
  const hostnameParts = window.location.hostname.split(".");
  return hostnameParts.slice(-2).join(".");
};

// Get just the subdomain (if present)
export const getSubdomain = () => {
  const hostnameParts = window.location.hostname.split(".");
  return hostnameParts.length > 2 ? hostnameParts[0] : null;
};
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatCurrency = (amount: number | string) => {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  if (isNaN(numericAmount)) return '₱0.00';

  return new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numericAmount);
}; 

// Peso Symbol Component
export const PesoIcon = () => (
  <span className="text-lg font-semibold">₱</span>
);

export const dynamicFieldsPattern = (val: string): string => {
  // Use a regular expression to remove the pattern like ".0." or ".1." and surrounding spaces
  // The pattern that resolves this is (String.key.string) this use for dynamic fields
  return val.replace(/\b\w+\.\d+\./g, "");
};

export const formikErrorSetter = (
  setFieldError: (field: string, message: string) => void,
  errorData: any,
  isDynamicFields: boolean = false
) => {
  for (const [key, value] of Object.entries<string>(errorData)) {
    if (Object.hasOwnProperty.call(errorData, key)) {
      if (isDynamicFields) {
        setFieldError(key, dynamicFieldsPattern(value));
      } else {
        setFieldError(key, value);
      }
    }
  }
};

export const ucfirst = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}