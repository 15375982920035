import api, { setNewAccessToken } from "./CoreRequest";
import { LogOutApiCall } from "./LoginRequest";
import { ApiResponse } from "../interfaces/ApiInterface";
import { setNavigation } from "../redux/slice";

export const getNavigationList = async (dispatch?: any, data?: any) => {
  try {
    const response = await api.get<ApiResponse>("/api/navigation/list", {
      params: data,
    });

    if (response.result?.success) {
      if (response.result?.access_token) {
        setNewAccessToken(response.result.access_token);
      }
    }
    return response;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      await LogOutApiCall(dispatch);
    }
    throw err;
  }
};

export const getNavigationSettingsList = async (dispatch?: any, data?: any) => {
  try {
    const response = await api.get<ApiResponse>(
      "/api/navigation/settings/list",
      {
        params: data,
      }
    );

    if (response.result?.success) {
      if (response.result?.access_token) {
        setNewAccessToken(response.result.access_token);
      }

      if (response.result?.data) {
        dispatch(setNavigation(response.result.data));
      }
    }
    return response;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      await LogOutApiCall(dispatch);
    }
    throw err;
  }
};
