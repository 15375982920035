import React, { useState, useEffect, useRef } from "react";
import { FaUser, FaSignOutAlt, FaCaretDown, FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLogout } from "../utils/Logout";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slice";
import { usePusher } from "../utils/PusherUtil";
import NotificationDropDownUtil from "../utils/NotificationDropDownUtil";
import { Notification, NotificationResponse } from "../interfaces/NotificationInterface"
import { NotificationType } from "../utils/Constant"
import { getList, markAsRead, markAsAllRead } from "../apiRequest/NotificationRequest";
import { useAppDispatch } from "../redux/hooks";
import SwalHelper from "../utils/SwalHelper";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const logout = useLogout();
  const user = useSelector(selectUser);

  const handleNewMessage = (data: NotificationResponse) => {
    setNotifications(prev => [{
      id: data.id,
      title: data.title,
      message: data.message,
      time: data.time,
      isRead: false,
      type: data.type as NotificationType,
      url: data.url || undefined
    }, ...prev]);
  };

  const pusherConfig = {
    appKey: process.env.REACT_APP_PUSHER_KEY ?? '',
    cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? '',
    channelName: user?.id ? `notification-channel-${user.id}` : '',
    eventName: user?.id ? `notification-event-${user.id}` : '',
    onMessage: handleNewMessage
  };

  const { isConnected, error } = usePusher(pusherConfig);

  const fetchNotifications = async () => {
    try {
      const res = await getList(dispatch);

      if (res.result?.success) {
        if (res.result?.data) {
          setNotifications(res.result?.data.map((v: NotificationResponse) => ({
            id: v.id,
            title: v.title,
            message: v.message,
            time: v.time,
            isRead: v.isRead,
            type: NotificationType,
            url: v.url || undefined
          })));
        }
      }
    } catch (error) {
      SwalHelper.fetchError("Notifications");
    }
  };

  const handleMarkAsRead = async (id: string) => {
    try {

      const res = await markAsRead(dispatch, id);

      if (res.result?.success) {
        setNotifications(prev =>
          prev.map(notification =>
            notification.id === id ? { ...notification, isRead: true } : notification
          )
        );
      }
    } catch (error) {
      SwalHelper.updateError("Notifications");
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const res = await markAsAllRead(dispatch);

      if (res.result?.success) {
        setNotifications(prev =>
          prev.map(notification => ({ ...notification, isRead: true }))
        );
      }
      
    } catch (error) {
      SwalHelper.updateError("Notifications");
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNotificationsOpen(false);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      notificationRef.current &&
      !notificationRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
      setIsNotificationsOpen(false);
    }
  };

  // Initial fetch of notifications when user is available and connected
  useEffect(() => {
    if (user?.id && isConnected) {
      fetchNotifications();
    }
  }, [user?.id, isConnected]);

  // Error handling effect
  useEffect(() => {
    if (error) {
      console.error('Pusher connection error:', error);
    }
  }, [error]);

  // Click outside handler
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const unreadCount = notifications.filter(n => !n.isRead).length;

  return (
    <header className="flex flex-col w-full p-4 bg-transparent">
      <div className="flex justify-between items-center mb-2">
        <div className="items-center space-x-4 lg:flex hidden font-poppins">
          <span className="text-base font-bold">
            Hello {user?.details.fname || "User"}
          </span>
          <span className="text-gray-500 flex items-center text-xs">
            <span className="mr-2">»</span>
            {new Date().toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </div>

        <div className="flex items-center space-x-4 relative">
          <div className="relative" ref={notificationRef}>
            <button
              onClick={toggleNotifications}
              className="flex items-center focus:outline-none relative p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <FaBell className="text-xl text-gray-600" />
              {unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center z-10">
                  {unreadCount}
                </span>
              )}
            </button>
            <NotificationDropDownUtil
              notifications={notifications}
              isOpen={isNotificationsOpen}
              onMarkAsRead={handleMarkAsRead}
              onMarkAllAsRead={handleMarkAllAsRead}
            />
          </div>

          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 focus:outline-none"
            >
              <img
                src={user?.details.profile_img || "https://via.placeholder.com/30"}
                alt={user?.details.fname || "User"}
                className="w-8 h-8 rounded-full object-cover"
              />
              <span className="text-sm font-medium hidden md:inline font-poppins">
                {user?.details.fname || "User"}
              </span>
              <FaCaretDown className="text-sm font-medium hidden md:inline" />
            </button>
            {isDropdownOpen && (
              <div className="text-sm absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20">
                <Link
                  to="/admin/profile"
                  className="block px-4 py-2 font-poppins text-gray-800 hover:bg-gray-100 flex items-center"
                >
                  <FaUser className="mr-2" /> Profile
                </Link>
                <hr className="border-gray-300 w-full mt-1.5" />
                <button
                  onClick={logout}
                  className="block w-full text-left px-4 py-2 font-poppins text-gray-800 hover:bg-gray-100 flex items-center"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="border-gray-300 w-full mt-1.5" />
    </header>
  );
};

export default Header;