import React, { Suspense, lazy, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardLayout from "./components/layouts/DashboardLayout";
import SpinnerLoader from "./components/utils/SpinnerLoader";

const Login = lazy(() => import("./components/pages/Login"));
const ForgotPassword = lazy(() => import("./components/pages/ForgotPassword"));
const Dashboard = lazy(() => import("./components/pages/Dashboard"));
const Users = lazy(() => import("./components/pages/Users"));
const Settings = lazy(() => import("./components/pages/Settings"));
const Departments = lazy(() => import("./components/pages/Departments"));
const Category = lazy(() => import("./components/pages/Category"));
const Roles = lazy(() => import("./components/pages/Roles"));
const Permission = lazy(() => import("./components/pages/Permission"));
const Expense = lazy(() => import("./components/pages/Expense"));
const Vendor = lazy(() => import("./components/pages/Vendor"));
const UserProfile = lazy(() => import("./components/pages/UserProfile"));

const App: React.FC = () => {
  // AuthenticatedRoutes is memoized since it doesn't depend on any props or state
  const AuthenticatedRoutes = useMemo(() => (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="settings" element={<Settings />} />
      <Route path="departments" element={<Departments />} />
      <Route path="categories" element={<Category />} />
      <Route path="vendor" element={<Vendor />} />
      <Route path="expenses" element={<Expense />} />
      <Route path="profile" element={<UserProfile />} />
      <Route path="authentication">
        <Route path="users" element={<Users />} />
        <Route path="roles" element={<Roles />} />
        <Route path="permissions" element={<Permission />} />
      </Route>
      <Route path="budget">
        <Route path="expenses" element={<Expense />} />
        <Route path="request" element={<Permission />} />
      </Route>
    </Routes>
  ), []); // Empty dependency array since routes don't change

  return (
    <Router>
      <Suspense fallback={<SpinnerLoader />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/admin/*"
            element={
              <DashboardLayout>
                {AuthenticatedRoutes}
              </DashboardLayout>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;